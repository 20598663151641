.tendency-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tendency-card > * {
    flex: 1 1 100%;
    padding: 0.4rem 0.8rem;
    max-width: 50%;
    transition: 0.2s;
    cursor: pointer;
}


.tendency-card.active, .tendency-card.active:hover {
    outline: 1px solid var(--color-primary) !important;
}



.tendency-card > span:first-child {
    font-weight: bold;
    min-width: 100%;
    margin-bottom: -1.4rem;
}
.tendency-card > span:nth-child(2) {
    align-self: flex-end;
    font-size: 1rem;
}
.tendency-card > span:nth-child(3) {
    align-self: flex-end;
    text-align: right;
}
.tendency-card > span:not(:first-child) {
    flex: 1;
}
.tendency-card > span:nth-child(3) {
    font-size: 0.8rem;
}

.tendency-card-arrow-container {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
}
.tendency-card-arrow-container > div {
    padding-left: 25%; 
    text-align: center;
}
.tendency-card-arrow {
    display: inline-block;
    height: 1.6rem;
    width: auto;
}
.tendency-card-variance {
    display: inline-block; 
    width: auto;
}
