/*Sidebar additional styles*/
.sidebar-dashboard-header-menu{
    width: 100%;
}

.sidebar-dashboard-header-menu .sidebar-dashboard-header-menu-item{
    position: relative;
    padding: 12px 14px;
    background-color: white;
    text-align: left;
    font-family: Mulish;
    font-size: 16px;
    margin-bottom: 15px;   
}
.sidebar-dashboard-header-menu-item::after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: blueviolet;
}


.sidebar-dashboard-header-menu-item:hover{
    cursor: pointer;
    background-color: #ffdebf1e;
}

.sidebar-dashboard-header-menu-item.active{
    background-color: #ffdebf;
}

/*Additional buttons styles*/
.primary-button {
    background-color: var(--color-primary);
    color: #fff;
    width: 250px;
    height: 30px;
    padding: 11px 4px;
    border-radius: 4px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.primary-button-text{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}

.admin-primary-button {
    background-color: #ff7b00;
    color: #fff;
    width: 300px;
    height: 30px;
    padding: 11px 4px;
    border-radius: 4px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}