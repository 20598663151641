.container-auth {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .text-header {
    font-size: 32px;
    margin-top: 18px;
    margin-bottom: 35px;
  }
  
  .header {
    background-color: #150725;
    color: #fff;
    height: 80px;
    display: flex;
    justify-content: flex-end;
  }
  
  .content {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    grid-template-areas: "auth-section background-section";
    grid-column-gap: 10px;
    height: 100vh;
  }
  
  .auth-section {
    grid-area: auth-section;
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  
  .background-section {
    grid-area: background-section;
    
    background: var(--color-primary);
    background: var(--color-gradient);
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    font-size: 62px;
    padding-right: 50px;
    color: #ffffff;
  }
  
  .min-width-background{
    display: none;
  }
  
  .input-leyend {
    font-size: 12px;
    color: #A0A0A0;
  }
  
  .error-input {
    border-color: #f55566;
  }
  
  .error-message {
    background-color: #f55566;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 20px;
  }
  
  .success-message {
    background-color: #2A9051;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 20px;
  }
  
  .button-submit,
  .button-submit:hover,
  .button-submit:not(:hover) {
    width: 100%;
    font-size: 12px;
    background: var(--color-primary);
    background: var(--color-primary);
    border-color: transparent;
  }

  .button-register:disabled{
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
}
  
  .button-register,
  .button-register:hover,
  .button-register:not(:hover) {
    width: 100%;
    font-size: 12px;
    background: var(--color-primary);
    background: var(--color-gradient-outline);
    border-color: transparent;
  }
  
  @media only screen and (max-width: 1024px) {
  
    .min-width-background{
      display: flex;
      width: 100vw;
      height: 100vh;
      flex-direction: column;
      position: absolute;
      z-index: 2000;
      height: 110vh;
    }
  
    .stepper{
      display: block;
    }
  
    .step-title{
      margin-left: 0px;
    }
  
    .locations-container{
      display: block;
    }
  
    .location-card{
      width: 100%;
      max-width: 100%;
    }
  
    .target-zone-container{
      display: block;
      height: 150%;
    }
  
  
    .target-zone-leftside-map{
      width: 100%;
      height: 40%;
    }
  
    .target-zone-form{
      width: 100%;
      height: 60%;
    }
  
    .channel-card-container-part-one{
      margin-top: 20px;
    }
    
    .channel-card-container-part-two{
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 5px;
      justify-content: space-between;
    }
  
    .always-on-container{
      display: block;
    }
  
    .weekly-spend-container {
      text-align: initial;
      line-height: 40px;
    }
  
    .weekly-spend-mobile{
      display: flex;
      width: 60% !important;
    }
  
    .weekly-spend-title{
      width: 275px;
    }
  
    .weekly-spend-value{
      padding-left: 15px;
    }
  
    .schedule-campaign-channel-card{
      display: block;
    }
  
    .schedule-campaign-channel-card-buttons{
      margin-top: 25px;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
  
    .ReviewAndPaySidebar{
      display: block;
    }
  
    .review-and-pay-container {
      flex-direction: column-reverse;
  }
  
    .review-and-pay-container-left-side{
      height: 300px;
      width: 100%;
    }
  
    .review-and-pay-container-right-side{
      width: 100%;
    }
  
    .confirmation-data{
      flex-direction: column;
    }
    
    
    .confirmation-card{
      width: 100%;
      margin-bottom: 25px;
    }
  
    .history-confirmation-card{
      width: 100%;
      margin-bottom: 25px;
    }
  
    .report-form-container{
      display: block;
    }
  
    .report-form-selects{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    }
  
  }
  
  @media only screen and (max-width: 1023px) {
  
    .content-body {
      display: block;
    }
  
    .checkout-sidebar-container {
      display: none;
    }
    
    .step-title{
      text-align: center
    }
  
    .mobile-sidebar-button{
      display: block;
      position: fixed;
      bottom: 0;
      z-index: 49;
    }
  
    .mobile-button-background{
      width: 100vw;
      background-color: #f4f4f4;
      padding: 25px;
      display: flex;
      justify-content: center;
    }
  
    .sidebar-dashboard-button{
      width: 80%;
    }
  
    .mobile-more-icon{
      position: fixed;
      bottom: 30px;
      right: 10px;
    }
  
    .target-zone-leftside-map{
      height: 300px;
    }
  
    .target-zone-form{
      margin-bottom: 50px;
      height: auto;
    }
  
    .mobile-preview-info-container{
      display: flex;
      background-color: #FF5566;
      align-items: center;
      justify-content: space-around;
    }
  
    .mobile-sidebar{
      top: 60px;
      right: 0;
      height: 100vh;
      width: 50vw;
      position: fixed;
      z-index: 100;
      background-color: white;
      padding: 25px;
      overflow: scroll;
    }
  
    .mobile-sidebar-oppacity{
      top: 60px;
      left: 0;
      width: 50%;
      height: 100vh;
      opacity: 0.8;
      position: fixed;
      background-color: black;
      z-index: 100;
    }
  
    .schedule-campaign-channel-card{
      display: flex;
    }
  
    .schedule-campaign-channel-card-buttons{
      display: none;
    }
  
    .schedule-mobile-button{
      display: inline;
    }
  
    .target-zone-mobile-buttons{
      align-self: center !important;
      margin-top: 28px;
      position: fixed;
      background: #f4f4f4;
      width: 100vw;
      height: 100px;
      bottom: 0;
      z-index: 1000;
      justify-content: center;
      padding-top: 25px;
    }
  
    .mobile-calendar{
      height: 500px !important;
    }
  
    .target-zone-mobile-button{
      display: inline;
    }
  
    .mobile-target-zone-button-height{
      height: 5vh;
    }
  
    .dashboard-sidebar{
      /* display: none; */
    }
    
    .report-sidebar-item {
      /*display: none;*/
    }
  
    .dashboard-mobile-sidebar{
      display: contents;
    }
  
    .mobile-hide{
       display: none; 
    }
  
    .mobile-sidebar-container{
      /* display: contents; */
    }
  
    .sidebar-container{
      height: fit-content;
      padding-bottom: 150px;
    }
  
    .stepper-icons{
      text-align: center;
    }
    
    .dashboard-chart{
      width: 100%;
      margin-bottom: 25px;
    }
  
    .dashboard-chart-container{
      display: contents;
    }
  
    .mobile-channel{
      display: flex;
    }
  
    .tablet-and-descktop-channel{
      display: none;
    }
  
    .target-zone-mobile-customer-view-margins{
      margin-top: 40px;
      margin-bottom: 40px;
    }
  
    .schedule-card-mobile-date{
      flex-direction: column;
    }
  
    .content-body-content{
      padding-bottom: 100px;
    }
  
    .sidebar-mobile-body{
      height: fit-content;
      padding-bottom: 150px;
    }
  
  }
  