.omnistats-col{
    //grid-row: span 2;
    //grid-column: span 2;
    grid-row: span 1;
    grid-column: span 2;
    /*height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    &>div {
        width: calc(50% - 20px);
        margin-top: 20px;

        &>span:first-child {
            margin-bottom: -0.2rem;
        }
    }*/
}

.ant-form {
    padding-top: 0;
    padding-bottom: 1rem;
}

.omnistats-horizontal-rule-container {  
    color: #999;
    flex: 0 0 100%;  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.omnistats-horizontal-rule-container>label {
    font-size: 14px; 
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
}

.omnistats-horizontal-rule-container>div { 
    flex-grow: 1;  
    border-top: 1px solid #999;
    margin-top: 0.4rem;
}