.confirmation-order > span {
    font-family: Mulish;
    color: #424242;
    font-size: 16px;
    font-weight: 900;
}

.confirmation-order {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    margin: 0;
}

.confirmation-text {
    margin-top: 17px;
    margin-bottom: 17px;
    font-family: Mulish;
    font-size: 24px;
    color: #424242;
}

.confirmation-text b {
    color: var(--color-primary) !important;
}


.confirmation-footer {
    width: 100%;
    font-family: Mulish;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #424242;
}

.confirmation-data {
    display: flex;
    justify-content: space-between;
}

.confirmation-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 38px 40px;
    width: 32%;
}

.confirmation-purchased-channel-container {
    display: flex;
    justify-content: space-between;
    font-family: Mulish;
    color: #424242;
    align-items: baseline;
    margin-top: 17px;
}

.confirmation-purchased-channel-title {
    font-size: 20px;
    margin-right: 10px;
}

.confirmation-purchased-channel-dates {
    font-size: 16px;
}

.comfirmation-resume-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.comfirmation-resume-data-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: Mulish;
    font-size: 20px;
    margin-bottom: 25px;
}

.confirmation-billed-and-payment {
    font-family: Mulish;
    font-size: 16px;
}
.confirmation-billed-and-payment-title {
    margin-bottom: 13px;
}

.confirmation-back {
    margin-top: 60px;
    margin-bottom: 40px;
    font-family: Mulish;
    font-size: 20px;
    color: #424242;
    cursor: pointer;
    width: fit-content;
}

.confirmation-container {
    display: flex;
    flex-wrap: wrap;
}

.confirmation-payment-info {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 350px;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .confirmation-order {
        flex: 1 0 auto;
        margin-right: 60px;
        max-width: 300px;
    }
    .confirmation-payment-info {
        flex: 1 0 auto;
    }
}
