.content-account-header {
  background-color: #150725;
  color: #fff;
  height: 80px;
  display: flex;
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-areas: "content-account-header-content content-account-header-sidebar";
}

.content-account-header-content {
  grid-area: content-account-header-content;
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-size: 16px;
}

.content-account-header-sidebar {
  grid-area: content-account-header-sidebar;
  padding-top: 20px;
}

.content-account-header-sidebar-welcome {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.content-account-header-sidebar-help {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  text-decoration: underline;
}

.content-account-header-sidebar-help:hover {
  cursor: pointer;
}

/**************************************************/

.content-account-body {
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-areas: "content-account-body-content content-account-body-sidebar";
  /* grid-column-gap: 10px; */
  height: 100vh;
}

.content-account-body-content {
  grid-area: content-account-body-content;
}

.content-account-body-sidebar {
  grid-area: content-account-body-sidebar;
  background-color: #ffebd9;
}

/**************************************************/

.account-sidebar-back-dashboard {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.account-sidebar-items {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.account-sidebar-item {
  background-color: #fff;
  width: 200px;
  height: 35px;
  padding-top: 5px;
  padding-left: 15px;
  border-left: solid #6837a0;
  margin-bottom: 20px;
}

/**************************************************/

.mobile-sidebar-button {
  display: none;
}

.stepper-icons {
  min-width: 509px;
}

/* Currently this library adds inline styles to this element, so we override it
with important*/
.MuiAvatar-circular.primary-icon {
  background-color: var(--color-primary) !important;
}

.active-location-icon path{
  stroke-width: 4 !important;
}

/*Not an optimal solution. Simply replaces anything with the old primary color to the primary color var.*/


.icon-grid *[fill="#FF7B00"] {
  fill: var(--color-primary);
}

.home-icon {
  width: 40px !important;
  padding-bottom: 5px;
}

.home-icon path{
  stroke:white;
}

.icon-grid:hover {
  cursor: pointer;
}

.stepper-icons *[fill="#FF7B00"] {
  fill: var(--color-primary);
}

.stepper-icons *[stroke="#FF7B00"] {
  stroke: var(--color-primary);
}

.stepper-icons:hover {
  cursor: pointer;
}
