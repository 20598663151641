/*
    File uploaders
*/
.file-upload-area{
    margin-bottom: 0px;
}

.file-upload-file-name{
    font-family: Mulish;
    font-weight: bold;
    margin-bottom: 5px;
}

.file-upload-remove-button{
    text-decoration: underline;
    font-family: Mulish;
    color: #1ba0fe;
}
.file-upload-remove-button:hover{
    cursor: pointer;
}

.file-upload-area input[type="file"]{
    display: none;
}

.template-download{
    font-family: Mulish;
    font-weight: bold;
    font-size: 14px;
    color: #1ba0fe;
    text-decoration: underline;
    margin-top: 16px;
    display: block;
}
.template-download:hover{
    text-decoration: underline;
}