

.inverted-button {
    border: 2px solid var(--color-primary);
    padding: 5px 14px;
    border-radius: 4px;
    text-align: center;
}

.inverted-button-text {
    background: var(--color-gradient-outline);
    width: -moz-fit-content;
    width: fit-content;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-settings {
    display: block;

}

.settings-icon {
    margin-right: 40px;
}

.settings-icon path {
    stroke: var(--color-primary) !important;
}

.rule-info {
    padding-left: 40px;
    padding-right: 40px;
}