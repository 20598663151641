.reports-page-container {
  box-sizing: border-box;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 35px;
}

.filter-input {
  width: calc(50% - 20px);
}

.report-header {
  display: flex;
  margin-bottom: 30px;
  &:not(.justify-start) {
    justify-content: space-between;
  }
}

.report-title {
  font-family: Mulish;
  font-size: 30px;
  &.report-location {
    font-size: 24px;
  }
}

.report-text {
  margin: 20px;
  width: 50%;
}

.report-form-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.report-form-selects {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.report-apply-button {
  background-color: var(--color-primary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 76px;
  margin: 30px 0 0 16px;
  color: #fff;
  border-radius: 3px;
  border-style: none;
  padding: 7px 10px;

  &.m-0 {
    margin: 0 !important;
  }
}

.report-alltable {
  th {
    background-color: #c1c1c1 !important;
    color: #3f4045 !important;
    padding: 7px 16px !important;
    &::before {
      display: none;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
  td {
    padding: 8px 16px !important;
    line-height: 14px;
  }
  tbody tr {
    &:first-of-type td {
      padding-top: 16px !important;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: end;
  padding: 16px 0;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    li {
      list-style: none;
      color: #3f4045;
      font-size: 14px;
      position: relative;
      width: 20px;
      text-align: center;
      .prev,
      .next {
        display: block;
        width: 7px;
        height: 7px;
        padding: 0;
        border: none;
        border-top: 1px solid;
        border-color: #3f4045 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent !important;
        border-radius: 0;
        visibility: visible;
        &[disabled] {
          border-color: #c1c1c1 !important;
        }
      }
      .prev {
        border-left: 1px solid;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      .next {
        border-right: 1px solid;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.report-back-button {
  position: relative;
  width: 10px !important;
  height: 10px;
  padding: 0;
  border: none;
  visibility: visible !important;
  border-color: #ff7b00 !important;
  border-left: 2px solid;
  border-top: 2px solid;
  transform: rotate(-45deg);
  margin: auto 11px auto 0;
}

.report-company-selector {
  position: relative;
  display: flex;
  button {
    font-size: 14px;
    line-height: 1;
    padding: 7px 16px;
    color: #3f4045;
    background-color: #fff;
    border: 1px solid #3f4045;
    margin-right: 8px;
    &.active {
      color: #fff;
      background-color: #3f4045;
      border-color: #fff;
    }
  }
}


.custom-tag-select .ant-select-selection-overflow{
  flex-wrap: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .ant-table-content table{
    table-layout: auto !important;
  }
}
