


/* width */
.customScrollBar::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
.customScrollBar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
