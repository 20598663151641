.qr-select-and-btns {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: min(73px, 10vw);
}

.qr-input-width {
    width: min(250px, 100%);
}

.qr-list {
    max-height: min(300px, 30vh); 
} 

.qr-btns {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: min(10px, 2vh);
}
.qr-btns > button {
    outline: none;
    padding: 14px;
    background-color: var(--color-primary);
    border: solid 1px var(--color-primary);
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.015em;
}
.qr-btns > button:hover {
    background-color: var(--color-active);
}
.qr-btns > span {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
}
.account-manager-item:hover {
    background-color: rgba(81, 107, 116, 0.096);
}

.account-manager-item.selected-zip{
    background-color: var(--color-active);
}

.account-manager-list {
    border-radius: 10px;
    border: solid var(--color-primary) 2px;
    overflow-y: scroll;
    max-height: 180px;
}
.account-manager-item {
    background-color: white;
    text-transform: uppercase;
    padding: 10px 28px;
    cursor: pointer;
}