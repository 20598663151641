.selector-container {
    display: flex;
    justify-content: space-between;
    gap: 25%;
    margin: 20px 0px;
    width: 80%;
}

.selector {
    width: 50%;
    border-radius: 3px;
}

.column {
    border-radius: 10px;
    border: solid #FF7B00 2px;
    height: 58vh;
    overflow-y: scroll;
}

.item {
    padding: 10px 28px;
    cursor: pointer;
}

.item:hover {
    background-color: var(--color-active);
}

.actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.actions-between {
    margin-top: 20px;
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.file-name {
    font-weight: bold;
}

.selected {
    background-color: var(--color-active);
}

.files-main-container{
    border: 2px solid var(--color-primary);
}

.drawer-top-bar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.drawer-header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flight-button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.filter-input {
    width: calc(50% - 20px);
    margin-bottom: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.no-files-icon{
    stroke: var(--color-primary);
}