.content-header {
    background-color: #3f3f46;
    color: #fff;
    height: 60px;

    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: auto;
}

.header-logo {
    align-self: center;
    width: auto;
    height: 90%;
    margin-left: 22px;
    cursor: pointer;
}

.content-header-logo {
    padding-left: 40px;
    /* margin-top: 10px; */
    line-height: 50px;
    font-family: Mulish;
    font-size: 40px;
    line-height: 50px;
    color: #ff7b00;
}

.content-header-sidebar-welcome {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    align-items: center;
}

.content-header-sidebar-welcome-text {
    font-family: Mulish;
    font-size: 16px;
}

.content-header-sidebar-avatar {
    clip-path: circle(50% at 50% 50%);
    width: 50px;
}

/**************************************************/

.content-body {
    transition: 0.5s;
    display: grid;
    grid-template-columns: 77% 23%;
    grid-template-areas: "content-account-body-content content-account-body-sidebar";
    /* grid-column-gap: 10px; */
    /* Calculated to not include height of content-header */
    height: calc(100vh - 60px);
}

.content-body.activeNewArea {
    grid-template-columns: 15rem calc(77% - 15rem) 23%;
    grid-template-areas: "activeChannelDashBoard content-account-body-content content-account-body-sidebar";

}

.content-body.activeNewArea .content-body-content {
    padding: 50px 50px 0px 50px;
}


.content-body.activeNewArea.openChannelSideBar {
    
    grid-template-columns: 5rem calc(77% - 5rem) 23%;
    grid-template-areas: "activeChannelDashBoard content-account-body-content content-account-body-sidebar";

}

.activeChannelDashBoard {
    min-width: 15rem;
}

.content-body-content {
    grid-area: content-account-body-content;
    background-color: #f4f4f4;
    box-sizing: border-box;
    padding: 50px;
    overflow: auto;
    height: 100%;
}

.content-body-sidebar {
    grid-area: content-account-body-sidebar;
    background-color: #ffffff;
    padding: 0px 25px;
}

/**************************************************/

.sidebar-dashboard {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.sidebar-dashboard-button {
    background: var(--color-primary) !important;
    background: var(--color-primary) !important;
    color: #fff;
    width: 100%;
    height: 48px;
    padding: 11px 4px;
    border-radius: 4px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-dashboard-button:disabled{
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
}

.sidebar-dashboard-button-disabled {
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none;
    color: rgba(0,0,0,.25) !important;
    text-shadow: none;
    cursor: not-allowed;
}

.sidebar-dashboard-button:hover,
.sidebar-dashboard-button:focus {
    background: var(--color-primary);
    background: var(--color-primary);
    color: #fff;
    opacity: 0.8;
}

.sidebar-dashboard-button > span {
    display: contents;
}

.sidebar-dashboard-button-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.sidebar-inverted-button {
    border: 2px solid var(--color-primary);
    margin-top: 16px;
    width: 100%;
    height: 48px;
    padding: 11px 4px;
    border-radius: 4px;
    text-align: center;
}
.sidebar-inverted-button:not(:disabled) .sidebar-inverted-button-text {
    background: var(--color-gradient-outline);
    width: fit-content;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sidebar-inverted-button:hover,
.sidebar-inverted-button:focus {
    border: 2px solid var(--color-primary);
    opacity: 0.8;
}

.sidebar-dashboard-header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.sidebar-dashboard-header-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sidebar-dashboard-body {
    display: flex;
    justify-content: center;
}

/**************************************************/

.dashboard-content-container {
    box-sizing: border-box;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
}

.dashboard-chart {
    width: 45%;
    margin: 0px;
}

.dashboard-calendar {
    height: 60%;
    min-height: 500px;
    margin-bottom: 20px;
}

.dashboard-mobile-sidebar {
    display: none;
}

.mobile-sidebar-container {
    display: none;
}

.dashboard-chart-container {
    display: flex;
    justify-content: space-between;
}
