@import "~antd/dist/antd.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.svg-color-primary * {
  stroke: var(--color-primary);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

/****/

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 5px;
}

.ant-input:hover {
  border-color: #531b93;
  border-right-width: 1px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #531b93;
  box-shadow: none;
}

.ant-input-search .ant-input:focus {
  border-color: #531b93;
}

.ant-input-affix-wrapper:hover {
  border-color: #531b93 !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #531b93;
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #531b93;
  border-radius: 5px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #531b93;
  box-shadow: none;
}


/*****/
.ant-tabs-tab:hover {
  color: #531b93;
}

.ant-tabs-tab {
  color: #a0a0a0;
  width: 250px;
  display: flex;
  justify-content: center;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #531b93 !important;
  font-weight: 500;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #531b93;
  pointer-events: none;
  height: 3px;
}

/******/
.pointer-hover:hover {
  cursor: pointer;
  opacity: 0.8;
}

/******/
.divider {
  border-top: 1px solid #000000;
  margin-bottom: 50px;
}

/******/
.general-container {
  width: 60%;
  height: 100vh;
  padding-top: 50px;
  padding-left: 50px;
}

.general-button,
.general-button:hover,
.general-button:not(:hover) {
  background-color: #696969;
  color: #fff;
  width: 200px;
  height: 35px;
  padding-top: 5px;
  border-radius: 3px;
  border-style: none;
}

.general-button:disabled{
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}

.inverted-general-button,
.inverted-general-button:hover,
.inverted-general-button:not(:hover) {
  background-color: #ffffff;
  color: var(--color-primary);
  width: 250px;
  height: 30px;
  padding: 11px 4px;
  border-radius: 4px;
  border-width: 1px;
  border-color: var(--color-primary);
}

.primary.general-button,
.primary.general-button:hover,
.primary.general-button:not(:hover) {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.inverted-general-button svg path{
  stroke: var(--color-primary);
}