.chart-main-container {
    padding: 0px 0rem;
}

.chart-controlers {
    padding-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.chart-controler-divider{
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}