:root {
    --carousel-footer-height: 64px;
    --arrow-size: 50px;
}
.templates-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.templates-template-title {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    font-size: 1.5rem;
}
.templates-carousel-container {
    padding: 16px;
    text-align: center;
    position: relative;
}
.templates-carousel-img-placeholder {
    height: min(50vh, 50vw);
    width: min(50vh, 50vw);
    background-color: #666;
    display: inline-block;
}
.templates-carousel-dots-container {
    display: flex;
    justify-content: center;
    padding: 12px;
    gap: 4px;
}
.templates-carousel-slide-footer {
    height: var(--carousel-footer-height);
    background: white;
}
.templates-carousel-arrow-left {
    position: absolute;
    height: var(--arrow-size);
    width: var(--arrow-size);
    left: calc(0%);
    top: calc(50% - var(--carousel-footer-height));
    display: inline-block;
    cursor: pointer;
}
.templates-carousel-arrow-right {
    position: absolute;
    height: var(--arrow-size);
    width: var(--arrow-size);
    left: calc(100% - var(--arrow-size));
    top: calc(50% - var(--carousel-footer-height));
    display: inline-block;
    cursor: pointer;
}
.templates-carousel-slide-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.templates-carousel-slide-img {
    height: min(50vh, 50vw);
    width: min(50vh, 50vw);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.templates-button-container {
    display: flex;
    justify-content: center;
}
.templates-button-with-gradient {
    -webkit-background-clip: text;
    background-clip: text;
    color: var(--color-primary);
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    border: 1px solid;
    border-color: var(--color-primary);
}
.templates-carousel-slide-check-icon {
    height: 35px;
    width: auto;
    position: absolute;
    top: 0px;
    right: 30px;
}
.templates-drawer-top-bar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.templates-channel-dropdown {
    display: flex;
    justify-content: center;
}
