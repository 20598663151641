.schedule-campaign-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 50px;

  .channel-card {
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px;
    margin: 0 0px 30px;
    flex-wrap: wrap;
    border: 1px solid var(--color-primary);
    filter: drop-shadow(0px 0px 8px rgba(21, 7, 37, 0.4));
    &.campaign-package {
      border: 1px solid var(--color-focus);
    }

    > * {
      flex: 1 0 100%;
      margin-bottom: 20px;
    }
    .title {
      font-weight: 600;
      font-family: Mulish;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: auto !important;
      .text {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        .details-link {
          padding: 0;
          display: none;
          color: #424242;
          font-size: 12px;
          height: auto;
          span {
            text-decoration: underline;
          }
          &:hover,
          &:focus {
            color: #000;
          }
        }
      }
    }
    .channel-date {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      .tooltip-container {
        align-self: end;
      }
    }
    .channel-end {
      margin: 0;
    }
  }
}

.always-on-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px;
    margin-right: 7px;
  }
  svg {
    width: 20px;
  }
}

@media screen and (min-width: 768px) {
  .schedule-campaign-container {
    .channel-card {
      padding: 30px;
      .title,
      .channel-date,
      .channel-end {
        flex: 0 0 auto;
        margin: 0;
      }
      .title {
        justify-content: start;
      }
      .channel-date {
        margin: 0 15px;
      }
      .channel-end {
        margin: 0 0 0 20px;
      }
    }
  }
}

.schedule-campaign-calendar-container {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.channel-scheduling-details-popover {
  background-color: #531b93;
  height: 100%;
  padding: 20px;
}

.channel-scheduling-details-popover p {
  color: #ffffff;
}

.schedule-sidebar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 39px;
  width: 100%;
  justify-self: center;
}

.schedule-calendar {
  height: 500px;
  min-height: 500px;
}

.channel-datepicker-label {
  display: flex;
  font-family: Mulish;
  font-size: 12px;
}

.channel-card .title svg path,
.channel-card .title svg rect,
.channel-card .title svg circle {
  stroke: var(--color-primary);
}
