.channels-container {
  margin-top: 50px;
  /* margin-left: 40px; */
}


.slider-spend.Mui-disabled {
  opacity: 0.5;
}

.campaigns-title {
  font-family: Mulish;
  font-size: 24px;
}

.package-card-container {
  background: #ffffff;
  filter: drop-shadow(0px 0px 8px rgba(21, 7, 37, 0.4));
  border: 1px solid #531b93;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.channel {
  background: #ffffff;
  filter: drop-shadow(0px 0px 8px rgba(21, 7, 37, 0.4));
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.channel-card-container-part-one {
  display: flex;
  margin-right: 20px;
}

.channel-card-container-part-two {
  display: contents;
}

.channel-title {
  font-family: Mulish;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 30vw;
}

.schedule-title {
  font-family: Mulish;
  font-size: 20px;
  display: flex;
  align-items: center;
  min-width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.channel-data-element {
  display: flex;
  font-family: Mulish;
  font-size: 16px;
}
.channel-data-title {
  font-weight: bold;
  margin-right: 4px;
}

.channel-template {
  color: #0096ff;
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 30px;
}

.channel-button {
  background-color: var(--color-primary);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  border-width: 0px;
  width: 149px;
  height: 36px;
}

.channel-button:disabled {
  opacity: 0.5;
}

.media-button {
  background-color: rgb(255, 255, 255);
  color: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: 4px;
  border-width: 2px;
  width: 150px !important;
  height: 36px;
}

.media-button:disabled {
  opacity: 0.5;
}

/* ///////////////////////////// */

.target-container {
  display: flex;
  margin-top: 20px;
  font-family: Mulish;
  font-size: 16px;
}

.target-title {
  font-weight: bold;
  margin-right: 4px;
}

/* ////////////////////////// */

.channel-sidebar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 39px;
}

.sidebar-card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(21, 7, 37, 0.4);
  border-radius: 8px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.sidebar-card svg path{
  stroke: var(--color-primary);
}

.sidebar-card-header {
  display: flex;
  justify-content: space-between;
}

.sidebar-card-header-title {
  font-family: Mulish;
  font-size: 24px;
  color: #424242;
}

.sidebar-card-investment {
  display: flex;
  font-family: Mulish;
  font-size: 16px;
}

.sidebar-card-investment-title {
  font-weight: bold;
  margin-right: 4px;
}

.sidebar-spend-by-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sidebar-spend-by-title {
  margin-top: 10px;
  font-family: Mulish;
  font-weight: bold;
  font-size: 24px;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
}

.channel-graphic {
  width: 325.05px;
  height: auto;
}

/* ////////////////////////////// */

.always-on-container {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.always-on-toogle-container {
  display: flex;
  align-items: center;
  font-family: Mulish;
  font-weight: bold;
  font-size: 20px;
  > span {
    margin: 0;
  }
  .text {
    margin: 0 12px;
  }
}

.always-on-title-active {
  margin-left: 15px;
  color: #424242;
}

.always-on-title-inactive {
  margin-left: 15px;
  color: #a0a0a0;
}

.weekly-spend-container {
  font-family: Mulish;
  font-weight: 600;
  text-align: right;
}

.weekly-spend-title {
  font-size: 18px;
}

.weekly-spend-value {
  font-size: 20px;
}

.template-img {
  flex: 40%;
  width: 25vw;
  border: 1px solid #150725;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-right: 15px;
  background-color: white;
  position: relative;
}

.channel-title svg path, .channel-title svg rect, .channel-title svg circle{
  stroke: var(--color-primary);
}

.mobile-channel {
  display: none;
}

/* @media screen and (max-width: 1200px) {
  .mobile-channel {
    display: block;
  }

  .channel-data {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .channel-data {
    display: block;
  }

  .mobile-channel {
    display: none;
  }
} */

