.onboarding_menu_active path {
  stroke: var(--color-primary);
}

/*Styles for the form components used accross all the onboarding section*/
.onboarding-main .ant-input {
  border: 1px solid;
  border-radius: 11px;
  padding: 11px 16px;
}

.onboarding-main .ant-input:hover,
.onboarding-main .ant-input:focus {
  border-right-width: 1px !important;
  border-width: 1px;
  border-color: #531b93;
}

.onboarding-main .ant-select {
  border-radius: 10px;
  border: 1px solid;
  padding: 0;
}

.onboarding-main .ant-select .ant-select-selector {
  width: 100%;
  border: none;
}

.onboarding-main
  form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
  border-radius: 9px !important;
}

.onboarding-main .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-right-width: 0;
}

.onboarding-main .ant-select-focused{
  border-color: #531b93;
}

.onboarding-main .ant-select-selection-item {
  display: flex;
  align-items: center;
}
