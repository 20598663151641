.modal > * {
    position: relative;
}

.modal {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    overflow-x: hidden;
    z-index: 1000;
    transition: 0.2s;
}

.modal-open {
    min-width: 80%;
}

.modal-closed {
    min-width: 0%;
    width: 0%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 1.2rem;
    font-size: 2rem;
}

.modal-content {
    padding: 1.2rem;
}

.modal-close-btn {
    background-color: transparent;
    border: none;
}
.modal-footer {
    display: "flex";
    justify-content: "flex-end";
}
.account-manager-list {
    border-radius: 10px;
    border: solid #ff7b00 2px;
    overflow-y: scroll;
}
.account-manager-item {
    background-color: white;
    text-transform: uppercase;
    padding: 10px 28px;
    cursor: pointer;
}
