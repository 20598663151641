.review-and-pay-container {
  /* height: calc(100% - 100px); */
  background-color: #ffffff;
  display: flex;
  border-radius: 10px;
  margin-top: 50px;
  overflow: hidden;
  min-height: 80%;
  height: fit-content;
}

.review-and-pay-container-left-side {
  width: 50%;
}

.review-and-pay-container-right-side {
  padding: 22px 28px;
  width: 50%;
}

.review-and-pay-container svg path[fill='#FF7B00']{
  fill: var(--color-secondary);
}

.billing-addres-container {
  margin-bottom: 30px;
  font-family: Mulish;
}

.review-and-pay-title {
  margin-bottom: 20px;
  font-size: 18px;
}

.review-and-pay-content {
  font-size: 16px;
}

.payment-dropdown {
  border: 2px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 7px 12px;
}

.review-pay-channel-resume {
  margin-top: 20px;
}

.review-pay-channel-resume-title {
  margin-bottom: 20px;
  font-family: Mulish;
  font-weight: bold;
  font-size: 20px;
}

.review-pay-channel-card {
  margin-bottom: 10px;
}

.review-pay-channel-card-title {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 0;
}

.review-pay-channel-card-content {
  font-family: Mulish;
  font-size: 14px;
}

.review-pay-sidebar-footer {
  text-align: end;
}

.review-pay-sidebar-footer-item {
  margin-top: 20px;
  font-family: Mulish;
  font-size: 24px;
}
.review-pay-sidebar-footer-item > p {
  font-size: 16px;
}

.target-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
  :first-child {
    font-weight: 900;
  }
}

.grand-total {
  font-size: 18px;
  font-weight: 900;
  margin-top: 0;
}
