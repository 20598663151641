@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Mulish"; 
  color: #424242; 
  height: 100vh;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

body > iframe {
  display: none;
}



@media screen and (max-width: 769px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 47px;
    padding: 6px 8px 0px 8px;
  }
  .content-body-content{
    padding-left: 25px;
    padding-right: 25px;
  }
}

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  border-right-width: 0;
}