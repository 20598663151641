.event-calendar-page-container {
  box-sizing: border-box;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
}

.rbc-month-view {
  border: none;
}

.rbc-month-view > * {
  border: none !important;
}

.rbc-header {
  border: none;
}

.rbc-header > * {
  border: none !important;
}

.rbc-header + .rbc-header {
  border: none;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: end;
}

/* .rbc-event {
  height: 1em;
} */

.rbc-show-more {
  background-color: transparent;
  /* cursor: default; */
}

.event-calendar{
  height: calc(100% - 86px);
  min-height: 500px;
}