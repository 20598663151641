.toggle-event-header{
    display: flex;
    height: 48px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
}

.toggle-event-option{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    line-height: 350%;
}

.event-card{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgb(21 7 37 / 40%);
    height: 95px;
    margin-top: 30px;
    padding: 17px 37px;
    align-items: center;
}

.delete-details{
    color: #0096FF;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.delete-card {
    height: 58px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    padding: 14px 57px;
}

.delete-card-option{
    color: #0096FF;
    cursor: pointer;
}