.franchise-select-and-btns {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: min(73px, 10vw);
}

.franchise-input-width {
    width: min(250px, 100%);
}

.franchise-list {
    max-height: min(300px, 30vh); 
} 

.franchise-btns {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: min(10px, 2vh);
}
.franchise-btns > button {
    outline: none;
    padding: 14px;
    background-color: #ff7b00;
    border: solid 1px #ff7b00;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.015em;
}
.franchise-btns > button:hover {
    background-color: #ff9100;
}
.franchise-btns > span {
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
}
.franchise-item:hover {
    background-color: #ffdebf;
}

.franchise-item-selected {
    background-color: #ffdebf;
}

.table-styled {
    width: 100%;
    border-collapse: collapse;
}

.table-styled th,
.table-styled td {
    padding: 8px;
    text-align: left;
}

.clickeable-text {
    color: #18aeff;
    cursor: pointer;
}
