.primary.general-link {
  background-color: transparent; /* No background for link-like look */
  color: ##FF7A00;                /* Link-like color */
  padding: 10px 20px;            /* Padding to make it feel clickable */
  border: none;                  /* Remove borders */
  display: inline-block;         /* Acts like an inline element */
  text-align: center;            /* Centers the text */
  text-decoration: underline;    /* Underline to resemble a link */
}

.pointer-hover {
  cursor: pointer;               /* Makes the cursor a pointer on hover */
}

.primary.general-link:hover {
  text-decoration: none;         /* Remove underline on hover */
}