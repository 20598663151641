.change-password{
    text-decoration: underline;
}

.change-password:hover{
    cursor: pointer;
}

.account-form {
    padding-top: 20px;
    width: 500px 
}