.icon-success {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.primary-icon path{
  stroke: var(--color-primary);
}