  .selector-container {
    display: flex;
    justify-content: space-between;
    gap: 25%;
    margin: 20px 0px;
    width: 80%;
  }

  .selector {
    width: 50%;
    border-radius: 3px;
  }
  
  .column {
    border-radius: 10px;
    border: solid #FF7B00 2px; 
    height: 58vh;
    overflow-y: scroll;
  }

  .item {
    padding: 10px 28px;
    cursor: pointer;
  }

  .item:hover {
    background-color: #ffdebf;
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .actions-between {
    margin-top: 20px;
    display: flex;
    width: 80%;
    justify-content: space-between;
  }

  .file-name {
    font-weight: bold;
  }

  .selected {
    background-color: #ffdebf;
  }
