.report-sidebar-item {
  background-color: #fff;
  width: 100%;
  height: 35px;
  padding-left: 15px;
  border-left: solid var(--color-primary);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.report-sidebar-item > p {
  margin: 0px;
}

.report-sidebar-item:hover {
  background-color: #ffdebf;
}

.report-sidebar-title {
  color: #424242;
  font-family: Mulish;
  font-size: 20px;
}
