.ant-carousel .slick-dots li button {
    height: 12px !important;
    width: 12px !important;
    border-radius: 12px !important;
    border: 1px solid var(--color-primary) !important;
    background-color: transparent !important;
}
.ant-carousel .slick-dots li.slick-active button {
    background-color: var(--color-primary) !important;
    width: 12px !important;
}
.ant-carousel .slick-dots li {
    width: 12px !important;
    height: 12px !important;
}