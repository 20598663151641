.campaign-history-header{
    margin-bottom: 85px;
}

.campaign-card {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 40px;
    box-shadow: 0px 0px 8px rgb(21 7 37 / 40%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-size: 16px;
}

.campaign-details{
    color: #0096FF;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.campain-detail-body{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.campaign-detail-title {
    font-size: 24px;
    margin-bottom: 30px;
}

.purchased-channel-card{
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgb(21 7 37 / 40%);
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    align-items: center;
    width: 548px;
    margin-bottom: 28px;
}

.purchased-channel-card-title{
    font-size: 24px;
}

.show-more{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80.21%);
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.history-confirmation-card{
    background-color: #FFF;
    border-radius: 10px;
    padding: 38px 40px;
    width: 48%;
}