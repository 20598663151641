.target-zone-container {
  /* calc top margin - height of stepper */
  height: calc(100% - 100px);
  background-color: #ffffff;
  display: flex;
  border-radius: 10px;
  margin-top: 40px;
  overflow: hidden;
}

.target-zone-leftside-map {
  width: 60%;
  position: relative;
}

.target-zone-container svg path[fill='#FF7B00']{
  fill: var(--color-secondary);
}


.target-zone-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px;
  width: 40%;
}

.location-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    b {
      font-weight: bolder;
    }
  }
  .change-link {
    padding: 0;
    color: var(--color-primary);
    font-size: 14px;
    span {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      color: #000;
    }
  }
}

.loader-icon path, .loader-icon animate{
  stroke: var(--color-active) !important;
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}

.target-zone-sidebar-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.target-zone-sidebar-card {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(21, 7, 37, 0.4);
  border-radius: 8px;
  padding: 13px 19px 0px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}

.target-zone-sidebar-card svg path{
  stroke: var(--color-primary);
}

.preview-zone-card {
  border: 1px dashed #000000;
  box-shadow: 0px 0px 0px !important;
}

.map-button-container {
  position: absolute;
  z-index: 50;
  background: rgba(196, 196, 196, 0.6);
  padding: 10px 21px;
}

.map-button {
  border: 0px;
  border-radius: 4px;
  width: 99px;
  height: 36px;
  padding: 5px 16px;
  font-family: Mulish;
  font-weight: bold;
  font-size: 14px;
}

.map-button-clear {
  color: var(--color-primary);
  background: #ffffff;
  margin-right: 24px;
}

.map-button-undo {
  color: #ffffff;
  background: var(--color-primary);
}

button.preview-save-zone-button {
  margin-left: 30px;
  background-color: var(--color-primary);
  border-radius: 4px;
  border-width: 0px;
  color: #ffffff;
}

.preview-save-zone-button:disabled {
  background-color: #a0a0a0;
  color: #fff;
}

.preview-save-zone-button:active {
  background-color: var(--color-primary);
  color: #ffffff;
}

.preview-save-zone-button:hover {
  background-color: var(--color-primary);
  color: #ffffff;
}

.preview-save-zone-button:focus {
  background-color: var(--color-primary);
  color: #ffffff;
}

.target-zone-switch-row {
  display: flex;
  align-items: center;
  height: 32px;
}

.target-zone-switch-row > p {
  margin: 0px;
}

.mobile-preview-info-container {
  display: none;
}

.mobile-preview-info-text {
  color: white;
}

.target-zone-mobile-button {
  display: none;
}

.target-zone-mobile-buttons {
  display: flex;
  flex-direction: column;
  padding: 16px 50px 16px 16px !important;
  > div,
  > button {
    width: 100%;
    margin: 0;
    height: 5vh;
    padding: 4px 15px;
    margin-bottom: 16px;
  }
}

.mobile-arrow svg path{
  stroke: var(--color-primary);
}
