.stepper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.stepper .stepper-icons {
  display: flex;
  transform: scale(0.5);
  transform-origin: left center;
}
.stepper .stepper-icons svg {
  display: block;
}

.step-title {
  font-family: Mulish;
  font-size: 24px;
}

.step-title-icon {
  width: 20vw;
}

.location-background {
}

.locations-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.location-card {
  border: 1px solid #150725;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 25px;
  margin-bottom: 30px;
  margin-right: 15px;
  background-color: white;
  position: relative;
  width: 31vw;
}

.location-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-card-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.active-location-legend {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
}

.active-location-icon path{
  stroke-width: 4 !important;
}

.location-card-location {
  display: flex;
  font-family: Mulish;
  font-size: 18px;
}

.location-card-location-title {
  margin-right: 5px;
}

.location-card-location-content {
  font-weight: bold;
}

.location-card-body {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.location-card-body-left-column {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-right: 5px;
  width: 80%;
}

.location-card-body-right-column {
  font-family: Mulish;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  bottom: 6%;
  right: 0;
}

.location-card-body-right-column-item {
  display: flex;
}

.details-link {
  padding: 0;
  color: #424242;
  font-size: 16px;
}

.details-link:hover,
.details-link:focus {
  color: #000;
}

.details-link span {
  text-decoration: underline;
}

.details-location {
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.details-title {
  font-size: 20px;
  text-align: center;
}
/* //////////////////////////////////// */

.sidebar-location-select-text {
  display: flex;
  flex-direction: column;
  font-family: Mulish;
  font-size: 24px;
}

.checkout-sidebar-container {
  height: 100%;
  padding: 0px 25px;
}

.sidebar-dashboard {
  flex-direction: column;
}

.location-card-radio {
  position: absolute !important;
  bottom: 4px;
  right: 6px;
}

.franchisees-container {
  border: 1.5px solid var(--color-primary);
  font-size: 16px;
  border-radius: 4px;
  margin-top: 20px;
  padding: 8px 0;
  max-height: 60vh;
  overflow: auto;
}

/* width */
.franchisees-container::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.franchisees-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.franchisees-container::-webkit-scrollbar-thumb {
  background: #ff7b0021;
  border-radius: 10px;
}

/* Handle on hover */
.franchisees-container::-webkit-scrollbar-thumb:hover {
  background: #ff7b0046;
}




.franchisee-email {
  padding: 5px 20px;
}

.franchisee-email.active {
  background-color: var(--color-active);
}

@media screen and (min-width: 1200px) {
  .location-card {
    width: 20vw;
  }
}
@media screen and (max-width: 980px) {
  .step-title-icon {
    width: 22vw;
  }
}
