.d-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    gap: 1.6rem;
    align-items: start;
}
.channel-selector-container {
    height: 100%;
    grid-row: span 1;
    grid-column: span 2;
    border-radius: 5px;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
}
.graph-container {
    grid-row: span 3;
    grid-column: span 4;
    /* background-color: white; */
    border-radius: 5px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    padding: 24px 0;
    height: 100%;
}
.bottom-data-container {
    grid-row: span 6;
    grid-column: span 6;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/*Fix styles from the filter form*/
.filter-main-container { 
    height: calc(100% + 10px);
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
    align-items: self-end;
}

.filter-input {
    width: calc(50% - 20px);
    margin-bottom: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.filter-submit {
    margin-bottom: 0 !important;
}
.filter-labels-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
}

.filter-labels-container > label {  
    font-size: 14px;
    display: inline-block;
    width: 50%;
    text-align: left;
    font-weight: bold;
}
