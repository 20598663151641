.svg-black * {
    stroke: black;
}


.selectMonth {
    border: 1px solid rgba(0, 0, 0, 0.671);
    background-color: white;
    display: flex;
    border-radius: 4px;
    padding: 3px 7px 1px 7px;
    width: fit-content;
    align-items: center;
}

.selectMonth .arrowPrev {
    transform: rotate(90deg);
}

.selectMonth .arrowNext {
    transform: rotate(-90deg);
}


.dividerCustom {  
    color: #999;
    flex: 0 0 100%;  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dividerCustom>label {
    font-size: 14px; 
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
}

.dividerCustom>div { 
    flex-grow: 1;  
    border-top: 1px solid #999;
    margin-top: 0.4rem;
}
