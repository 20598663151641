.admin-button{
    background-color: #ff7b00;
    color: #fff;
    width: 60%;
    height: 30px;
    padding: 11px 4px;
    border-radius: 4px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.admin-button-text{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}
