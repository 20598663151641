.side-panel-container {
    background: rgba(0, 0, 0, 0.50);
    position: fixed;
    display: flex;
    justify-content: right;
    left: 0px;
    top: 0px;
    z-index: 5;
    width: 100vw;
    margin-top: 60px;
    height: calc(100vh - 60px);
}

.side-panel-container-hidden {
    display: none;
}

.side-panel {
    padding: 20px;
    background-color: #fff;
    flex-basis: 30%;
    border-radius: 0px;
    overflow-y: scroll;
}

.close-img {
    float: right;
    cursor: pointer; 
}

.container-form {
    position: relative;
}

.checkbox-inline .ant-form-item-control {
  display: flex;
  align-items: center;
}