
.background-home {
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/background.svg);
}
