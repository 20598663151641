.user-payment-methods-page-container {
  box-sizing: border-box;
  height: 100%;
}

.user-payment-methods-saved-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.user-payment-methods-saved-card > div > p,
.user-payment-methods-saved-card > div > h2,
.user-payment-methods-saved-card > p,
.user-payment-methods-saved-card > h2 {
  margin: 0px;
}

.user-payment-methods-saved-card svg path{
  stroke: var(--color-primary);
}

.user-payment-methods-saved-card-delete-option {
  color: #0096ff;
  font-weight: 600;
  cursor: pointer;
}
