.admin-page{
    
}

/*
    Basic font styles
*/
h1{
    font-family: Mulish;
    font-size: 35px;
}

/*Area de errores*/
.error-block{
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    right: 200px;
    bottom: 0;
    padding: 50px;

}

.error-block .close-window{
    position: absolute;
    top: 20px;
    right: 30px;
}

.error-block .confirm-button{
    position: absolute;
    right: 30px;
    bottom: 20px;
    min-width: 150px;
}
.error-block .close-window:hover{
    cursor: pointer;
}

.error-list{
    margin-top: 50px;

}

.error-list p{
    margin-bottom: 15px;
}

.content-body-content{
    position: relative;
}