.footer-support {
  left: 0;
  bottom: 140px;
  width: fit-content;
  margin-bottom: 20px;
  color: var(--color-primary);
  text-decoration-line: underline;
}

.footer-guides {
  left: 0;
  bottom: 140px;
  width: fit-content;
  margin-bottom: 20px;
  color: var(--color-primary);
  text-decoration-line: underline;
}

.footer-request {
  left: 0;
  bottom: 140px;
  width: fit-content;
  margin-bottom: 100px;
  color: var(--color-primary);
  text-decoration-line: underline;
}


.footer-support:hover {
  cursor: pointer;
}

.footer-guides:hover {
  cursor: pointer;
}

.footer-request:hover {
  cursor: pointer;
}
