.section-leyend {
  display: flex;
}

.password-forgot {
  color: var(--color-primary);
  text-decoration-line: underline;
}

.password-forgot:hover {
  cursor: pointer;
}

.checkbox-remenber {
  margin-left: auto;
}



.ant-checkbox-inner,
.ant-checkbox-inner:hover,
.ant-checkbox-inner::after {
  border-color: var(--color-primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
